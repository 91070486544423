.inputs_course {
  position: relative;

  h1 {
    font-family: Inter;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(0, 97, 247, 1);
    margin-top: 32px;
    margin-left: 80px;
    margin-bottom: 32px;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    margin: 0 80px;
    flex-wrap: wrap;
    color: rgb(60, 57, 57);

    li {
      width: 320px;
      height: 88px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;

      span {
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-align: left;
      }

      input,
      select {
        width: 100%;
        background: #ffffff;
        border: 1px solid rgba(47, 73, 209, 0.6);
        border-radius: 8px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        height: 48px;
        padding: 13px;
        outline: none;
      }

      select {
        appearance: none;
        cursor: pointer;
      }
    }

    .rasm {
      label {
        width: 100%;
        background: #ffffff;
        border: 1px solid rgba(47, 73, 209, 0.6);
        border-radius: 8px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        height: 48px;
        padding: 13px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }
    }
  }

  button {
    width: 320px;
    height: 48px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    background: #2f49d1;
    border-radius: 8px;
    color: #ffffff;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 80px;
    margin-top: 32px;
    cursor: pointer;
  }
}

.list_course {
  h1 {
    margin-top: 150px;
    font-family: Inter;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0.01em;
    text-align: left;
    color: rgba(0, 97, 247, 1);
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;

    li {
      width: 700px;
      height: auto;
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
      border-radius: 16px;
      position: relative;
      padding-top: 60px;
      gap: 12px;

      img {
        display: block;
        width: 280px;
        height: 150px;
        margin: 0 auto;
        border-radius: 5px;
        margin-bottom: 20px;
      }

      h2 {
        width: 100%;
        text-align: center;
        background-color: blue;
        position: absolute;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: left;
        color: #ffffff;
        top: 0;
        left: 0;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
      }

      p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        // text-align: left;

        i {
          margin-left: 15px;
          width: 180px;
        }

        span {
          color: rgba(0, 16, 98, 1);
          font-family: Inter;
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
          letter-spacing: 0.01em;
          text-align: left;
        }
      }

      b {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 30px;
      }
    }
  }
}

.b {
  background-color: #0000008c;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
}

.modal_course {
  position: absolute;
  top: 0;
  left: 20%;
  top: 100px;
  width: 60%;
  height: 550px;
  z-index: 500;
  background-color: #fff;
  border-radius: 16px;
  padding: 30px;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    margin: 0 80px;
    flex-wrap: wrap;
    color: rgb(60, 57, 57);

    li {
      width: 280px;
      padding: 15px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;

      span {
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-align: left;
      }

      input,
      select {
        width: 100%;
        background: #ffffff;
        border: 1px solid rgba(47, 73, 209, 0.6);
        border-radius: 8px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        height: 48px;
        padding: 13px;
        outline: none;
      }

      select {
        appearance: none;
        cursor: pointer;
      }
    }

    img {
      width: 40px !important;
      margin-top: 15px !important;
      margin-left: 110px !important;
    }

    .rasm {
      label {
        width: 100%;
        background: #ffffff;
        border: 1px solid rgba(47, 73, 209, 0.6);
        border-radius: 8px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        height: 48px;
        padding: 13px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }
    }
  }

  button {
    width: 280px;
    height: 48px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    background: #2f49d1;
    border-radius: 8px;
    color: #ffffff;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 110px;
    margin-top: 32px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1098px) {
  .inputs_course {
    h1 {
      margin-left: 0px;
    }

    ul {
      margin: 0;
    }

    button {
      right: 0;
    }
  }
}

@media screen and (max-width: 926px) {
  .inputs_course {
    ul {
      li {
        width: 200px;
      }
    }

    button {
      width: 200px;
    }
  }
}

@media screen and (max-width: 682px) {
  .inputs_course {
    h1 {
      font-size: 30px;
      margin-top: 10px;
    }

    ul {
      li {
        width: 150px;
      }
    }

    button {
      width: 150px;
    }
  }
}

@media screen and (max-width: 598px) {
  .inputs_course {
    ul {
      li {
        width: 180px;
      }
    }
  }
}

@media screen and (max-width: 446px) {
  .inputs_course {
    ul {
      li {
        width: 140px;
      }
    }
  }
}