table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 30px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.25);
  margin-bottom: 50px;
  overflow: auto;
}

th,
td {
  text-align: center;
  padding: 10px;
}

.th {
  background-color: rgba(47, 73, 209, 1);
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  padding: 20px;
  color: #fff;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background: rgba(0, 28, 175, 0.1);
}

td {
  border-bottom: 1px solid #ddd;
}

.videoSelect {
  width: 250px;
  margin-top: 200px;
  background: #e9e9e9;
  border: 1px solid rgba(47, 73, 209, 0.6);
  border-radius: 8px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  height: 48px;
  padding: 13px;
  outline: none;
  appearance: none;
  cursor: pointer;
}

@media screen and (max-width: 926px) {}

