@import "../src/utils/scss/normalize.scss";
@import "../src/utils/scss/fonts.scss";
@import "../src/utils/scss/var.scss";
@import "../src/utils/scss/dark.scss";

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
}

.none {
  display: none;
}

#root {
  height: 100%;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

.Sabinas_logo {
  padding-top: 10px;
  margin-left: 10px;
}

.ant-layout {
  height: 100%;
}

.ant-layout-sider-children,
.ant-menu-inline {
  background-color: blue !important;
  color: #fff !important;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: left;
  position: relative;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  overflow: auto;
  margin: 0px !important;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.menuDark {
  background-color: #161624;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  & .ant-menu-root {
    background-color: #161624 !important;
  }
}

.menu {
  position: fixed;
  height: 100%;
  width: 300px;
  top: 0;
  padding: 0;
}

.menu_Menu {
  z-index: 12;
  height: 100%;
  top: -40px;
}

.zed {
  background-color: blue;
  position: relative;
  z-index: 5;
  width: 300px;
  left: -10px;
  height: 100px;
  padding-left: 15px;
  padding-bottom: 30px;
}

.menu_orqasi {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.521);
}

.menu_dark {
  background-color: #161624 !important;

  & .zed {
    background-color: #161624 !important;
    gap: 20px;
    color: yellow;
  }

  & .menu_Menu {
    background-color: #161624 !important;
  }
}
