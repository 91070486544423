.main_dark {
  .txt {
    color: #fff;
  }

  .statistika {
    background: #222235;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
    padding: 32px;
    border-radius: 20px;
  }
  .card_statistika {
    .item {
      background-color: #222235;
      color: #fff;

      span {
        color: #fff;
      }
    }
  }
}
