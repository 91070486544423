.list_open {
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 55px;

    li {
      width: 320px;
      height: auto;
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
      border-radius: 16px;
      position: relative;
      padding-top: 60px;
      gap: 12px;

      img {
        width: 280px;
        height: 150px;
        margin: 0 auto;
        border-radius: 5px;
        margin-bottom: 20px;
      }

      h2 {
        width: 100%;
        text-align: center;
        background-color: blue;
        position: absolute;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: left;
        color: #ffffff;
        top: 0;
        left: 0;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
      }

      p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;

        i {
          margin-left: 15px;
          width: 180px;
        }

        span {
          color: rgba(0, 16, 98, 1);
          font-family: Inter;
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
          letter-spacing: 0.01em;
          text-align: left;
        }
      }

      b {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 30px;
      }
    }
  }
}

.modal_course {
  position: absolute;
  top: 0;
  left: 20%;
  top: 100px;
  width: 60%;
  height: 550px;
  z-index: 500;
  background-color: #fff;
  border-radius: 16px;
  padding: 30px;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    margin: 0 80px;
    flex-wrap: wrap;
    color: rgb(60, 57, 57);

    li {
      width: 280px;
      padding: 15px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;

      span {
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-align: left;
      }

      input,
      select {
        width: 100%;
        background: #ffffff;
        border: 1px solid rgba(47, 73, 209, 0.6);
        border-radius: 8px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        height: 48px;
        padding: 13px;
        outline: none;
      }

      select {
        appearance: none;
        cursor: pointer;
      }
    }

    img {
      width: 40px !important;
      margin-top: 15px !important;
      margin-left: 110px !important;
    }

    .rasm {
      label {
        width: 100%;
        background: #ffffff;
        border: 1px solid rgba(47, 73, 209, 0.6);
        border-radius: 8px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        height: 48px;
        padding: 13px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }
    }
  }

  button {
    width: 280px;
    height: 48px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    background: #2f49d1;
    border-radius: 8px;
    color: #ffffff;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 110px;
    margin-top: 32px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1423px) {
  .openWorkbookbtn{
    top: 210px !important;
  } 
}

@media screen and (max-width: 401px) {
  .openWorkbookbtn{
    top: 210px !important;
  } 
}

@media screen and (max-width: 810px) {
  .list_open {
    ul {

      li {
        width: 190px !important;
      }
    }
  }
}

@media screen and (max-width: 690px) {
  .list_open {
    ul {
      
      li {
        width: 150px !important;

        h2 {
          font-size: 15px !important;
        }

        span{
          font-size: 13px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 435px) {
  .list_open {
    ul {
      
      li {
        width: 250px !important;
        margin: 0 auto;

        h2 {
          font-size: 20px !important;
        }

        span{
          font-size: 18px !important;
        }
      }
    }
  }
}