.txt {
  color: blue;
}

.title {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
}

.statistika {
  background: rgba(255, 255, 255, 0.422);
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
  padding: 32px;
  border-radius: 20px;
}

.card_statistika {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 32px;

  .item {
    width: 545px;
    height: 228px;
    background: #ffffff;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    position: relative;
    font-family: Inter;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -1px;
    text-align: left;
    padding: 20px;
    color: rgba(0, 0, 255, 0.775);

    img {
      position: absolute;
      width: 100px;
      height: 100px;
      bottom: 20px;
      right: 20px;
    }

    span {
      font-family: Inter;
      font-size: 32px;
      font-weight: 500;
      line-height: 39px;
      letter-spacing: -1px;
      text-align: left;
      color: rgba(0, 0, 0, 0.675);
      white-space: nowrap;
    }
  }
}

@media screen and (max-width: 1396px) {
  .card_statistika {
    .item {
      width: 400px;
    }
  }
}

@media screen and (max-width: 1084px) {
  .card_statistika {
    .item {
      width: 300px;
      font-size: 27px;

      span {
        font-size: 25px;
      }

      img {
        width: 80px;
        height: 80px;
      }
    }
  }
}

@media screen and (max-width: 884px) {
  .card_statistika {
    .item {
      width: 220px;
      height: 200px;
      font-size: 22px;

      span {
        font-size: 20px;
      }

      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}

@media screen and (max-width: 724px) {
  .card_statistika {
    .item {
      width: 180px;
      height: 180px;
      font-size: 20px;

      span {
        font-size: 18px;
      }

      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .card_statistika {
    .item {
      width: 160px;
      height: 160px;
      font-size: 16px;
      padding: 15px;

      span {
        font-size: 15px;
        margin: 0;
        margin-bottom: -10px;
      }

      img {
        width: 40px;
        height: 40px;
        right: 10px;
        bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 406px) {
  .card_statistika {
    .item {
      width: 130px;
      height: 130px;
      font-size: 16px;
      padding: 8px;

      span {
        font-size: 15px;
      }

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}

@media screen and (max-width: 991.9px) {
  .body {
    padding: 1rem;
    width: calc(100% - 54px);
  }
  .title {
    font-size: 20px;
  }
}

@media (min-width: 0) and (max-width: 449.9px) {
  .body {
    display: none;
  }
}
